import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue')
  },
  {
    path: '/businessMovement',
    name: 'businessMovement',
    component: () => import('../views/businessMovement.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs.vue')
  },
  {
    path: '/caseDetails',
    name: 'caseDetails',
    component: () => import('../views/caseDetails.vue')
  },
  {
    path: '/product',
    name: 'productPage',
    component: () => import('../views/product.vue')
  },
  {
    path: '/companyProducts',
    name: 'companyProducts',
    component: () => import('../views/companyProducts.vue')
  },
  {
    path: '/successfulCase',
    name: 'successfulCase',
    component: () => import('../views/successfulCase.vue')
  },
  {
    path: '/industryTrends',
    name: 'industryTrends',
    component: () => import('../views/industryTrends.vue')
  }
]

const router = new VueRouter({
  mode:'history',
  routes,
  scrollBehavior (to, from, savedPosition) { //解决跳转下一个页面底部的方法
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
 }
})

export default router
