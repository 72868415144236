<template>
  <div class="footer">
    <el-row style="padding-bottom:30px;">
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div class="title">产品服务</div>
          <div class="list">
            <a href="javascript: void(0)">票务系统</a>
            <a href="javascript: void(0)">酒店系统</a>
            <a href="javascript: void(0)">员工系统</a>
            <a href="javascript: void(0)">商户系统</a>
            <a href="javascript: void(0)">大数据系统</a>
            <a href="javascript: void(0)">向导系统</a>
            <a href="javascript: void(0)">电商系统</a>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple-light">
          <div class="title">服务对象</div>
          <div class="list">
            <a href="javascript: void(0)">景区</a>
            <a href="javascript: void(0)">酒店</a>
            <a href="javascript: void(0)">商户</a>
            <a href="javascript: void(0)">向导</a>
          </div>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple">
          <div class="title">关于我们</div>
          <div class="list">
            <a href="/aboutUs">关于我们</a>
            <a href="javascript: void(0)">车主权益</a>
            <a href="javascript: void(0)">充电解决方案</a>
          </div>
        </div>
      </el-col>
      <el-col :span="7">
        <div class="grid-content bg-purple-light">
          <div class="title">官方平台</div>
          <div class="list">
            <a href="javascript:void(0)">
              <div class="platform">
                <img src="@/assets/phone.png" alt="" />
                <span>咨询热线：020-234233432</span>
              </div>
            </a>
            <a href="javascript:void(0)">
              <div class="platform">
                <img src="@/assets/address.png" alt="" />
                <span>地址：广州市番禺区钟村街汉兴东路10号2212房</span>
              </div>
            </a>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="bottom">
      <p>Copyright © 2016-2022广州牵景科技有限公司</p>
      <a class="bottomA" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" >粤ICP备2021033345号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterPage",
  components: {},
  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
.bottomA:hover{
  color: #fff;
}
.grid-content{
  text-align: initial;
}
.footer {
  background-color: #002241;
  padding: 80px 100px 40px;
  .title {
    color: #138eff;
    font-size: 24px;
    margin-bottom: 37px;
    text-align: initial;
  }
  .list {
    display: flex;
    flex-direction: column;
    a {
      color: #cde9f9;
      text-decoration: none;
      font-size: 16px;
      margin-bottom: 21px;
      .platform {
        display: flex;
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  .bottom {
    border-top: 1px solid #555;
    color: #cde9f9;
    font-size: 13px;
    padding-top: 30px;
  }
}
</style>
