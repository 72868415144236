<template>
  <div class="home">
    <!-- 轮播图 -->
    <el-carousel :interval="5000" arrow="always" style="height: 700px">
      <el-carousel-item v-for="item in swiperList" :key="item.id" style="height: 700px">
        <el-image :src="item.url"></el-image>
      </el-carousel-item>
    </el-carousel>
    <!-- 过往景区运营以门票销售为主 -->
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark">
          <img class="bg2" src="../assets/bg2.png" alt="" />
        </div>
      </el-col>
    </el-row>
    <!-- 当前景区以多业态融合商圈化 -->
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark boxPadding">
          <div class="column">
            <div class="columnHeadings">
              <div class="dot"></div>
              <h1>当前景区以多业态融合商圈化</h1>
              <div class="dot"></div>
            </div>
            <p class="columnHeadingsText">
              整合景区合馆信息管理及数字化，提升用户游玩过程体验
            </p>
          </div>
          <div class="content">
            <div class="contentLeft">
              <img src="../assets/Frame58@2x.png" alt="" />
              <div class="tag">
                <img src="@/assets/Ellipse107@2x.png" alt="">
                <span>线上店铺</span>
              </div>
              <div class="tag tag1">
                <span>数字化运营</span>
                <img src="@/assets/Ellipse107@2x.png" alt="">
              </div>
              <div class="tag tag2">
                <img src="@/assets/Ellipse107@2x.png" alt="">
                <span>景区周边延伸</span>
              </div>
            </div>
            <div class="contentRight">
              <div class="item">
                <h3>第一生态：数字化运营</h3>
                <div>业务中台（产品、订单、营销用户）；</div>
                <div>游玩项目、水上乐园、滑雪场、观光车、游船；</div>
                <div>门票、酒店、商户、餐饮、包车、导游、摄影、剧场、温泉</div>
              </div>
              <div class="item" style="margin:20px 0;">
                <h3>第二生态：景区周边延伸</h3>
                <div>周边景区、特产、商城特卖；</div>
                <div>酒店住宿、餐饮服务、休闲娱乐；</div>
                <div>包揽吃、住、行、游、购、娱等服务</div>
              </div>
              <div class="item">
                <h3>第三生态：线上店铺</h3>
                <div>还原当地美食、体验、风情</div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 营销是推动业态运转的动力 -->
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark boxPaddingBgc" style=" background-color: #f8fcff; background-image: none;height: 500px;">
          <div class="column">
            <div class="columnHeadings">
              <div class="dot"></div>
              <h1>营销是推动业态运转的动力</h1>
              <div class="dot"></div>
            </div>
            <p class="columnHeadingsText">帮助景区促活、增量、提升</p>
          </div>
          <div class="content">
            <img class="business" v-for="(itemx, indexx) in imgList" :key="indexx" :src="itemx.url" alt=""/>
          </div>
          <div class="more">查看更多</div>
        </div>
      </el-col>
    </el-row>
    <!-- 公司核心产品 -->
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark boxPaddingBgc">
          <div class="column">
            <div class="columnHeadings">
              <div class="dot"></div>
              <h1>公司核心产品</h1>
              <div class="dot"></div>
            </div>
          </div>
          <swiper style="padding: 0 100px;" class="my_swiper" :options="swiperOption">
            <swiper-slide v-for="(item, index) in RectangleList" :key="index" @click.native="handleSwiper">
              <div class="my_swiperbox">
                <img :src="item.url" alt="" class="swiperImg" :data-path="item.url" data-yaya="图片"/>
                <img class="swiperIcon" :src="item.icon" alt="" />
                <div class="swiperTitle">{{ item.title }}</div>
                <ul style="padding-right:20px;">
                  <li v-for="(items, indexs) in item.list" :key="indexs">
                    {{ items }}
                  </li>
                </ul>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
          <div class="more" style="margin:20px auto;" @click="toProduct">查看更多</div>
        </div>
      </el-col>
    </el-row>
    <!-- 过往成功案例 -->
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark boxPaddingBgc" style="background: #f8fcff; height: 560px" >
          <div class="column">
            <div class="columnHeadings">
              <div class="dot"></div>
              <h1>过往成功案例</h1>
              <div class="dot"></div>
            </div>
            <p class="columnHeadingsText">
              来自大量优质客户对我们的信赖，实力的见证
            </p>
          </div>
          <swiper class="my_columnSwiperbox" :options="swiperOption">
            <swiper-slide v-for="(successItem, successIndex) in successfulCaseList" :key="successIndex" class="caseSwiper">
              <div class="columnSwiperbox">
                <img class="successImg" :src="successItem.url" fit="fill" />
                <div class="successfulCaseContent">
                  <div class="title">{{ successItem.title }}</div>
                  <div class="line"></div>
                  <div class="text">
                    <p v-for="(itemList, itemIndexs) in successItem.list" :key="itemIndexs">
                      {{ itemList }}
                    </p>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
          <div style="margin-top:50px;" class="more" @click="toSuccessfulCase">查看更多</div>
        </div>
      </el-col>
    </el-row>
    <FooterPage></FooterPage>
  </div>
</template>

<script>
import FooterPage from "@/components/footerPage.vue";
export default {
  name: "HomeView",
  components: { FooterPage },
  data() {
    return {
      swiperList: [
        {
          id: 0,
          url: require("@/assets/swiper1.png"),
        },
        {
          id: 1,
          url: require("@/assets/2.jpg"),
        },
        {
          id: 2,
          url: require("@/assets/3.jpg"),
        },
        {
          id: 3,
          url: require("@/assets/3.jpg"),
        },
      ],
      RectangleList: [
        {
          id: 0,
          title: "牵景票务系统",
          url: require("@/assets/Rectangle1.png"),
          icon: require("../assets/user-circle@2x.png"),
          list: [
            "旅游景区票务管理系统，覆盖“售、取、检、管理、结算”，无障碍对接第三方支付，轻松实现“购票-取票-验票”一体化，多渠道满足游客需求。.",
            "支持旅行社在线报团，供应商计调下单，支持一团多单，团队成员分码领票..",
          ],
        },
        {
          id: 1,
          title: "牵景酒店系统",
          url: require("@/assets/2.jpg"),
          icon: require("@/assets/user-circle@2x.png"),
          list: [
            "用于管理相关的人员信息，包括景区员工、商户员工、临时人员、VIP等；",
            "并通过该子系统控制相应独立系统（大数据端、核销端、商户端、员工端）的权限;同时为景区提供员工巡更巡检服务。",
          ],
        },
        {
          id: 2,
          title: "牵景员工系统",
          url: require("@/assets/3.jpg"),
          icon: require("@/assets/user-circle@2x.png"),
          list: [
            "用于管理相关的人员信息，包括景区员工、商户员工、临时人员、VIP等；",
            "支持旅行社在线报团，供应商计调下单，支持一团多单，团队成员分码领票..",
          ],
        },
        {
          id: 3,
          title: "牵景员工系统",
          url: require("@/assets/1.jpg"),
          icon: require("@/assets/user-circle@2x.png"),
          list: [
            "旅游景区票务管理系统，覆盖“售、取、检、管理、结算”，无障碍对接第三方支付，轻松实现“购票-取票-验票”一体化，多渠道满足游客需求。.",
            "支持旅行社在线报团，供应商计调下单，支持一团多单，团队成员分码领票..",
          ],
        },
      ],
      successfulCaseList: [
        {
          id: 0,
          title: "江西婺源篁岭",
          url: require("@/assets/successfulCaseList1.png"),
          list: [
            "集索道、景区门票、二消体验项目、商铺、酒店住宿综合业态型景区，",
            "年游客量超150万人，中国乡村旅游的典范。",
          ],
        },
        {
          id: 1,
          title: "广西北海海丝首港",
          url: require("@/assets/2.jpg"),
          list: [
            "集索道、景区门票、二消体验项目、商铺、酒店住宿综合业态型景区，",
            "年游客量超150万人，中国乡村旅游的典范。",
          ],
        },
        {
          id: 0,
          title: "江西婺源篁岭",
          url: require("@/assets/successfulCaseList1.png"),
          list: [
            "集索道、景区门票、二消体验项目、商铺、酒店住宿综合业态型景区，",
            "年游客量超150万人，中国乡村旅游的典范。",
          ],
        },
        {
          id: 0,
          title: "江西婺源篁岭",
          url: require("@/assets/successfulCaseList1.png"),
          list: [
            "集索道、景区门票、二消体验项目、商铺、酒店住宿综合业态型景区，",
            "年游客量超150万人，中国乡村旅游的典范。",
          ],
        },
      ],
      imgList: [
        { url: require("@/assets/index1.png") },
        { url: require("@/assets/index2.png") },
        { url: require("@/assets/index3.png") },
        { url: require("@/assets/index4.png") },
        { url: require("@/assets/index5.png") },
      ],
      // Swiper 配置项
      swiperOption: {
        direction: "horizontal", // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical
        slidesPerView: 'auto', // Swiper的列数
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          type: "bullets", //将分页的类型改为圆点（默认)
          clickable: true, // 点击可切换
        },
        slidesPerView: 3,
        grid: {
          fill: "column",
          rows: 2,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置自动轮播
        // autoplay: {
        //   // delay: 1000, // 2秒切换一次
        //   disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        // },
        loop: true, //无缝轮播
        grabCursor: true, // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
      },
    };
  },
  methods: {
    // 鼠标点击页面
    handleSwiper(event) {
      let dataset = event.target.dataset;
      console.log(dataset);
      console.log(dataset.path, dataset.yaya); // 自定义，可获取相应的值
      // 跳转
      // window.location.href = "https://www.baidu.com"
    },
    toProduct(){
      this.$router.push('./product') 
    },
    toSuccessfulCase(){
      this.$router.push('./successfulCase') 

    }
  },
};
</script>
<style scoped lang="less">
.my_columnSwiperbox{
  height: 350px;
}
.successfulCaseContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  text-align: start;
  width: 35%;
  border-radius: 0 10px 10px 0;
  .line{
    width: 30px;
    height: 4px;
    margin-top: 8px;
    background-color: #138EFF;
    border-radius: 2px;
  }
}
.caseSwiper{
  width: 625px !important;
  margin: 0 15px;
}
.columnSwiperbox{
  display: flex;
  height: 300px;
  // width: 600px;
  border-radius: 10px 10px 0 0;
  background: #fff;
}
.my_swiper{
  height: 550px;
  margin-top: 50px;
  .swiperIcon{
    width: 60px;
    height: 60px;
  }
  .swiperTitle{
    font-size: 17px;
    font-weight: 600;
    margin-top: 8px;
  }
  .swiperImg{
    border-radius: 10px 10px 0 0;
  }
}
.successImg {
  width: 70%;
  border-radius: 10px 0 0 10px;
}
.my_swiperbox{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
}
.my_swiper>.swiper-slide {
  height: 100%;
}
.my_swiper img{
  display: block;
  width: 100%; 
  height: 270px;
}
/* 设置小圆点样式 */
.my_swiper /deep/ .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color:#000;
    opacity: 1;
    background: rgba(0,0,0,0.2);
}
.swiper-button-prev:after,.swiper-button-next:after{
  content:'';
}
/* 设置小圆点激活样式 */
.my_swiper /deep/ .swiper-pagination-bullet-active {
    color:#fff;
    background: #151391;
}
/deep/ .el-carousel__arrow {
  top: 100%;
}
.bg2 {
  width: 100%;
}
.boxPadding {
  padding: 80px 0 140px;
  background-image: url("../assets/bg3.png");
  background-size: 100% 100%;
  height: 500px;
}
.boxPaddingBgc {
  padding: 80px 0 20px;
  background-image: url("../assets/Group.png");
  background-size: 100% 100%;
  height: 750px;
}
.column {
  .columnHeadings {
    display: flex;
    align-items: center;
    justify-content: center;
    .dot {
      width: 15px;
      height: 15px;
      background-color: #138eff;
      border-radius: 10px;
      box-shadow: 0px 2px 10px 1px rgba(19, 142, 255, 0.52);
    }
    h1 {
      margin: 0 20px;
    }
  }
  .columnHeadingsText {
    color: #acacac;
    font-size: 14px;
  }
}
.content {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  margin-top: 40px;
  .contentLeft {
    img {
      width: 500px;
      height: 500px;
      position: relative;
    }
    .tag{
      position: absolute;
      top: 30%;
      left: 37%;
      display: flex;
      align-items: center;
      color: #138EFF;
      img{
        width: 30px;
        height: 30px;
      }
    }
    .tag1{
      top: 48%;
      left: 15%;
    }
    .tag2{
      top: 84%;
      left: 35%;
    }
  }
  .contentRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .business {
    width: 200px;
    height: 210px;
    border-radius: 10px;
  }
    .title {
      font-size: 20px;
      font-weight: 600;
      text-align: start;
      color: #333333;
    }
  }
.more {
  width: 200px;
  height: 45px;
  line-height: 45px;
  background-color: #138eff;
  color: #fff;
  border-radius: 23px;
  margin: 70px auto 70px;
  cursor:pointer;
}
</style>
