<template>
  <div id="app">
    <nav :style="scroll>80?'background-image: linear-gradient(#408ed7,#5cabe0);':''">
      <img style="width:150px;" src="@/assets/logo.png" alt="">
      <div style="margin-left:200px;">
        <router-link to="/">首页</router-link>
        <router-link to="/product">公司产品</router-link>
        <router-link to="/successfulCase">成功案例</router-link>
        <router-link to="/industryTrends">行业动态</router-link>
        <router-link to="/aboutUs">关于我们</router-link>
        <router-link to="/contactUs">联系我们</router-link>

        <!-- <router-link to="/companyProducts">产品详情</router-link> -->
        <!-- <router-link to="/businessMovement">行业动态详情</router-link> -->
        <!-- <router-link to="/caseDetails">成功案例详情</router-link> -->
      </div>
    </nav>
    <router-view/>
  </div>
</template>
<script>
export default {  
  data () {
    return {
      scroll: 0
    }
  },
  mounted () {
    window.addEventListener('scroll',this.handleScroll)
  },
  methods: {
    handleScroll(){
        //或者使用document.querySelector('.class或者#id').scrollTop
        this.scroll = document.documentElement.scrollTop||document.body.scrollTop
        // console.log(this.scroll)
    }
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body { padding:0;margin:0;background:#fff; color:#555; font-size:14px; font-family: Verdana, Arial, Helvetica, sans-serif; }
td,th,caption { font-size:14px; }
address, caption, cite, code, dfn, em, strong, th, var { font-style:normal; font-weight:normal;}
a { color:#555; text-decoration:none; }
a:hover { text-decoration:none; }
img { border:none; }
input, textarea, select, button { font:14px Verdana,Helvetica,Arial,sans-serif; }
table { border-collapse:collapse; }
html {overflow-y: scroll;} 

.clearfix:after {content: "."; display: block; height:0; clear:both; visibility: hidden;}
.clearfix { *zoom:1; }


nav {
  padding: 30px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  a {
    color: #fff;
    font-size: 16px;
    margin: 0 12px;
    &.router-link-exact-active {
      color: #fff;
      border-bottom: 2px solid #fff;
      padding-bottom: 5px;
    }
  }
  a:hover{
    color: #73EDE6;
  }
}
</style>
